import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default function useUsersEdit() {
  const userData = ref(null)
  const toast = useToast()
  const { t } = useI18nUtils()

  const getUserData = () => store.dispatch('app-user/getUser', { id: router.currentRoute.params.id })
    .then(response => {
      userData.value = response.data
    })
    .catch(error => {
      if (error.response.status === 404) {
        userData.value = undefined
      }
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error al traer los datos del cliente',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const editUserData = data => {
    store.commit('enableLoader')
    store.dispatch('app-user/editUser', data)
      .then(response => {
        userData.value = response.data
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Cliente editado con éxito',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
        store.commit('disableLoader')
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.error ? error.response.data.error : 'Error al editar los datos del cliente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        store.commit('disableLoader')
      })
  }
  const genderOptions = [
    { text: 'Masculino', value: 1 },
    { text: 'Femenino', value: 2 },
    { text: 'No binario', value: 3 },
  ]

  const resolveUserStatus = status => {
    if (status === false) return t('Clients.status.inactive')
    if (status === true) return t('Clients.status.active')
    return t('Clients.status.pending')
  }
  const statusFilter = ref(null)

  const statusOptions = [
    { label: 'Activo', value: true },
    { label: 'Inactivo', value: false },
  ]
  return {
    editUserData,
    statusFilter,
    statusOptions,
    resolveUserStatus,
    genderOptions,
    getUserData,
    userData,
    t,
  }
}
