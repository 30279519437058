<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <!-- Media -->
        <b-media class="mb-2">
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src="userData.id.toString()"
              text=""
              :variant="`light-primary`"
              size="90px"
              rounded
            />
          </template>
          <h4 class="mb-2 ">
            {{ userData.last_name +", " + userData.first_name }}
          </h4>
          <b-row>

            <b-col
              cols="2"
              md="2"
            >
              <b-link
                on
                variant=""
                @click="$refs.refInputEl.click()"
              >
                <b-avatar
                  id="refInputEl"
                  variant="light-primary"
                  rounded
                >
                  <feather-icon
                    icon="UploadIcon"
                    size="20"
                  />
                </b-avatar>
                <input
                  v-if="inputImageRenderer"
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="inputImageRenderer"
                >
                <b-tooltip
                  triggers="hover"
                  target="refInputEl"
                  :title="t('actions.editTitleAvatar')"
                  :delay="{ show: 1000, hide: 50 }"
                />
              </b-link>
            </b-col>
            <b-col
              cols="2"
              md="2"
              class="ml-lg-0"
            >
              <b-link
                on
                variant="light-danger"
              >            <b-avatar
                             id="delete-icon"
                             variant="light-danger"
                             rounded
                           >
                             <feather-icon
                               icon="Trash2Icon"
                               size="20"
                             />
                           </b-avatar>
                <b-tooltip
                  triggers="hover"
                  target="delete-icon"
                  :title="t('actions.deleteTitleAvatar')"
                  :delay="{ show: 1000, hide: 50 }"
                />

              </b-link>
            </b-col>
          </b-row>

        </b-media>
      </b-col>
      <b-col
        cols="12"
        md="4"
      />
      <b-col
        cols="12"
        md="4"
      >
        <!-- Header: Status Info -->
        <div class="d-flex">
          <feather-icon
            icon="InfoIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ t('Clients.statusTitle') }}
          </h4>
        </div>
        <b-row class="mt-1 ">
          <!-- Status -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('message.tableHeader.status')"
              label-for="user-status"
            >
              <v-select
                v-model="userData.active"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :value="userData.active"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <template>
      <!-- BODY -->
      <validation-observer
        #default="{invalid}"
        ref="refFormObserver"
      >
        <!-- User Info: Input Fields -->
        <b-form
          id="formEditClient"
          class="p-2"
          @submit.prevent="editUserData(userData)"
          @reset.prevent="resetForm"
        >
          {{ setClientId }}
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <feather-icon
              icon="UserIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              {{ t('Clients.titleInformation') }}

            </h4>
          </div>
          <b-row class="mt-1">
            <!-- Name -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('Clients.data.first_name')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Clients.data.first_name')"
                  label-for="first_name"
                >
                  <b-form-input
                    id="first_name"
                    :maxlength="50"
                    v-model="userData.first_name"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('Clients.data.first_name')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
            <!-- last Name -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- Last Name -->
              <validation-provider
                #default="validationContext"
                :name="$t('Clients.data.last_name')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Clients.data.last_name')"
                  label-for="last_name"
                >
                  <b-form-input
                    id="last_name"
                    :maxlength="50"
                    v-model="userData.last_name"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('Clients.data.last_name')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- DNI -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- rules="required|digits:8"-->
              <validation-provider
                #default="validationContext"
                :name="$t('Clients.data.dni')"
                rules="required|integer|between:999999,999999999">
                <b-form-group
                  :label="$t('Clients.data.dni')"
                  label-for="dni"
                >
                  <b-form-input
                    id="dni"
                    :maxlength="9"
                    v-model="userData.dni"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('Clients.data.dni')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Birth Date -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('Clients.data.birthday')"
              >
                <b-form-group
                  :label="$t('Clients.data.birthday')"
                  label-for="birth_date"
                >
                  <flat-pickr
                    id="birth_date"
                    v-model="userData.birth_date"
                    class="form-control"
                    :config="{ dateFormat: 'd-m-Y'}"
                    placeholder="DD-MM-YYYY"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </validation-provider>
            </b-col>
            <!-- Mobile -->
            <b-col
                cols="12"
                md="4"
            >
              <validation-provider
                  #default="validationContext"
                  :name="$t('Clients.data.phone')"
                  rules="integer|between:9999999,999999999999">
                <b-form-group
                    :label="$t('Clients.data.phone')"
                    label-for="phone"
                >
                  <b-form-input
                      id="phone"
                      :maxlength="12"
                      v-model="userData.phone"
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('Clients.data.phone')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!--  Email -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('Clients.data.email')"
                rules= "email"
              >
                <b-form-group
                  :label="$t('Clients.data.email')"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    :maxlength="50"
                    v-model="userData.email"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('Clients.data.email')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>            </b-col>
          </b-row>
          <!-- Address Info Form -->
          <b-row>
            <!-- Gender -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('Clients.data.sex')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Clients.data.sex')"
                  label-for="sex"
                  label-class="mb-1"
                >
                  <b-form-radio-group
                    id="sex"
                    v-model="userData.sex"
                    :options="genderOptions"
                    value=""
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- Header: Address Info -->
          <div class="d-flex mt-2">
            <feather-icon
              icon="MapPinIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              Dirección
            </h4>
          </div>
          <!-- Form: Personal Info Form -->
          <b-row class="mt-1">
            <!-- Field: Address Line 1 -->
            <b-col
              cols="12"
              md="6"
              lg="8"
            >
              <!-- Address -->
              <validation-provider
                #default="validationContext"
                :name="$t('Clients.data.address')"
              >
                <b-form-group
                  :label="$t('Clients.data.address')"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    :maxlength="240"
                    v-model="userData.address"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('Clients.data.address')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Postcode -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Código postal"
                label-for="postcode"
              >
                <b-form-input
                  id="postcode"
                  type="number"
                  placeholder="5710"
                  readonly
                />
              </b-form-group>
            </b-col>

            <!-- Field: City -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Ciudad"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  placeholder="La punta"
                  readonly
                />
              </b-form-group>
            </b-col>

            <!-- Field: State -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Provincia"
                label-for="state"
              >
                <b-form-input
                  id="state"
                  placeholder="San luis"
                  readonly
                />
              </b-form-group>
            </b-col>

            <!-- Field: Country -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="País"
                label-for="country"
              >
                <b-form-input
                  id="country"
                  placeholder="Argentina"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Buttons -->
          <b-row class="mt-2 mr-1">
            <b-col>
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="invalid"
                @click="cloneUserData()"
              >
                {{ t('Clients.buttons.save') }}
                <feather-icon
                  v-if="loader"
                  icon="SettingsIcon"
                  size="15"
                  class="spinner"
                />
              </b-button>
              <b-button
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="resetUserData()"
              >
                {{ t('Clients.buttons.reset') }}
              </b-button>
            </b-col>
            <b-link
                :to="{ name: 'apps-clients-view', params: { id: userData.id } }"
                class="font-weight-bold d-block text-nowrap"
            >
              <feather-icon
                  icon="ArrowLeftIcon"
                  size="15"
              />
              Volver
            </b-link>
          </b-row>

        <!-- PERMISSION TABLE -->
        <!--    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card>-->
        </b-form>
      </validation-observer>
    </template>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormInvalidFeedback, BFormRadioGroup, BLink, BTooltip, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { avatarText } from '@core/utils/filter'
import { required, alphaNum, email } from '@validations'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import useUsersEdit from '@/views/Clients/clients-edit/useUsersEdit'
import moment from 'moment'
import { loader } from '@core/mixins/ui/globalMixin'

export default {
  components: {
    BBadge,
    BTooltip,
    BLink,
    BFormRadioGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    flatPickr,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  mixins: [loader],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    setClientId() {
      this.userData.id = this.$router.currentRoute.params.id
    },
    setDateToString() {
      if (this.userData.birth_date) {
        this.userData.birth_date = moment(this.userData.birth_date).format('DD-MM-YYYY')
      }
    },
  },
  created() {
    this.setDateToString
    this.cloneUserData()
  },
  methods: {
    resetUserData() {
      this.userData.first_name = this.userDataAux.first_name
      this.userData.last_name = this.userDataAux.last_name
      this.userData.dni = this.userDataAux.dni
      this.userData.email = this.userDataAux.email
      this.userData.phone = this.userDataAux.phone
      this.userData.address = this.userDataAux.address
      this.userData.birth_date = this.userDataAux.birth_date
      this.userData.sex = this.userDataAux.sex
      this.userData.active = this.userDataAux.active
    },
    cloneUserData() {
      this.userDataAux = JSON.parse(JSON.stringify(this.userData)) // Clonar el objeto userData para asignarlo a la variable reactiva
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      id: null,
      first_name: null,
      last_name: null,
      dni: null,
      email: null,
      phone: null,
      address: null,
      birth_date: null,
      sex: null,
      plan: null,
      active: null,
    }
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)
    const {
      genderOptions, t, resolveUserStatus, statusOptions, statusFilter, editUserData,
    } = useUsersEdit()

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const userDataAux = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      editUserData,
      userDataAux,
      statusFilter,
      statusOptions,
      refFormObserver,
      getValidationState,
      resetForm,
      genderOptions,
      avatarText,
      resolveUserStatus,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
